.total {
  background-color: white;
  width: 90vw;
  height: 80vh;
  border-radius: 20px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  transition: all 0.7s ease;
}

.closeBtn {
  background-color: white;
  border-radius: 50px;
  height: 3vh;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  transition: all 0.7s ease;
  transition-delay: 1s;
}

.closeBtnIcon {
  transition: all 0.7s ease;
  color: black;
  margin: 1em 1em 0 0;
}

.selectWrapper {
  margin-top: 6%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.sectionMentS {
  transition: all 2s ease;
  opacity: 1;
}

.sectionMent {
  opacity: 0;
}

.selectBtnWrapS {
  transition: all 2s ease;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 14rem;
  overflow: overlay;
}

.selectDoneBtn {
  opacity: 0;
}

.selectDoneBtnS {
  transition: all 3s ease;
  opacity: 1;
}

.selectBtnWrap {
  opacity: 0;
}

.selectBtn {
  color: rgba(76, 76, 76, 1);
  margin-bottom: 5%;
  border: 2px solid rgba(87, 245, 255, 1);
  border-radius: 5px;
  height: 8vh;
  width: 46vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: rgba(132, 248, 255, 1);
}

.selectBtnW {
  color: rgba(76, 76, 76, 1);
  margin-bottom: 5%;
  padding: 10% 0 10% 0;
  border: 2px solid rgba(87, 245, 255, 1);
  border-radius: 5px;
  height: 7vh;
  width: 46vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: rgba(132, 248, 255, 1);
}

.selectBtn:hover {
  color: rgba(76, 76, 76, 1);
  margin-bottom: 5%;
  border: 2px solid rgba(0, 191, 255, 1);
  border-radius: 5px;
  height: 8vh;
  width: 46vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: rgba(132, 248, 255, 1);
}
.selectBtnW:hover {
  padding: 10% 0 10% 0;
  color: rgba(76, 76, 76, 1);
  margin-bottom: 5%;
  border: 2px solid rgba(0, 191, 255, 1);
  border-radius: 5px;
  height: 8vh;
  width: 46vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: rgba(132, 248, 255, 1);
}

.textAreaDiv {
  vertical-align: middle;
}

.textArea {
  width: 60vw;
  height: 100%;
  border: 6px solid rgba(132, 248, 255, 1);
  border-radius: 5%;
  padding-top: 5%;
  font-size: 1rem;
}

.textArea::placeholder {
  text-align: center;
  margin-top: 10vh;
}

.notDoneBtn {
  opacity: 0.5;
  background-color: rgba(222, 253, 255, 1);
  color: black;
  font-size: 1.3rem;
  border-radius: 10px;
  width: 35vw;
  height: 8vh;
  align-content: center;
  font-weight: bolder;
  margin-top: 10%;
}
.doneBtn {
  background-color: rgba(222, 253, 255, 1);
  color: black;
  font-size: 1.3rem;
  border-radius: 10px;
  width: 35vw;
  height: 8vh;
  align-content: center;
  font-weight: bolder;
  margin-top: 10%;
}

.writeSumWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected {
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: fit-content;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.selected p {
  font-size: 1rem;
  margin-bottom: 15%;
  font-weight: 700;
}

.remindBtn {
  color: rgba(76, 76, 76, 1);
  height: 6vh;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
}

.sumDoneBtn {
  color: rgba(76, 76, 76, 1);
  border-radius: 10px;
  background-color: rgba(222, 253, 255, 1);
  height: 8vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 20%;
}
