/* 
@media (prefers-reduced-motion: no-preference) {
} */

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
  font-family: Arial;
  margin-top: 2.5rem;
}
.h1 {
  color: white;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  font-size: 3rem;
  width: max-content;
  height: 9svh;
  transition: all 0.8s ease;
}

.h2 {
  color: white;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  font-size: 3rem;
  width: max-content;
  height: 11svh;
  transition: all 0.8s ease;
}

.section {
  background-color: white;
  color: deepskyblue;
  text-align: center;
  border-radius: 10px;
  width: 80%;
}

.ment {
  font-size: 2rem;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.8s ease;
}

.yuda {
  opacity: 1;
  font-size: 3rem;
  transform: translateY(0);
  transition: all 0.8s ease;
  margin-top: 15%;
}

.ment.animating {
  font-size: 2rem;
  transform: translatey(100%);
  opacity: 0;
}

.yuda.animating {
  font-size: 3rem;
  transform: translateY(-100%);
  opacity: 0;
}

.button {
  color: white;
  color: rgb(0, 115, 255);
  width: 25%;
  height: 10%;
  font-size: large;
  border-radius: 20px;
  border: 1px rgb(0, 115, 255);
  padding: 2.5%;
}

.button:hover {
  font-size: larger;
  color: aqua;
}
