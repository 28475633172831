.total {
  background-color: white;
  font-family: Arial;
  border-radius: 20px;
  width: 75vw;
  display: flex;
  flex-direction: column;
}

.div {
  display: flex;
  flex-direction: column;
  margin: 10%;
}

input {
  margin-bottom: 10%;
  width: 50vw;
}

.divpw {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.pwInput {
  width: 50vw;
  margin-bottom: 3%;
}
.pwInputVal {
  width: 50vw;
  border: 1px solid red;
}
.checkBtnValid {
  border: 1px solid red;
  background-color: red;
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
  padding: 3%;
}

.checkBtn {
  font-size: 0.9rem;
  border-radius: 10px;
  border: 1px solid deepskyblue;
  background-color: deepskyblue;
  color: white;
  padding: 3%;
}

.sendBtn {
  border-radius: 10px;
  border: 1px solid deepskyblue;
  background-color: deepskyblue;
  color: white;
  font-size: 1.5rem;
  width: fit-content;
  padding: 5%;
  margin-left: 15%;
}

.idDupleSpinDiv {
  display: flex;
  margin-bottom: 5%;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
}
.pwConditionString {
  margin-top: 0;
  font-size: 0.8rem;
}
