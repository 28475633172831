html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none; /* iOS에서의 스크롤 관련 이슈 방지 */
  -webkit-overflow-scrolling: touch; /* 부드러운 스크롤 */
  background: linear-gradient(rgba(87, 245, 255, 1) 60%, rgba(0, 191, 255, 1));
}

:root {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
