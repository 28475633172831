.diary {
  background-color: #defdff;
  height: 10vh;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5%;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1.5;
}
.date p {
  margin: 10% 0 10% 0;
  color: #4c4c4c;
  text-align: center;
  font-family: Arial;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.date span {
  font-size: 0.6rem;
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 2;
}
.content p {
  color: #4c4c4c;
  font-family: Arial;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  line-height: normal;
}

.img {
  width: 15%;
  flex-grow: 1.5;
}

.img img {
  width: 100%;
  height: 100%;
}

.nullDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}
.nullMsg {
  color: black;
  font-weight: 900;
  font-size: small;
}
