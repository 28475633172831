.lowNav {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.5rem;
  height: 10vh;
}

button {
  background-color: inherit;
  color: white;
  border: inherit;
  display: grid;
  justify-items: center;
}

.icon {
  font-size: xx-large;
}

.btnP {
  margin-top: 0.5rem;
  font-size: large;
}
